import { useCalculator } from "~/composables/useCalculator";
import { useCalculatorApi } from "~/api/useCalculatorApi";

export function useSwapDirections() {
  const isAvailable = ref(false);
  const { fromDirection, toDirection, onCurrenciesChange } = useCalculator();
  const { fetchPair } = useCalculatorApi();

  const directionsIds = computed(() => {
    return [fromDirection.value?.id, toDirection.value?.id];
  });

  async function checkAvailability() {
    try {
      const [fromId, toId] = directionsIds.value as [number, number];
      const response = await fetchPair(toId, fromId);
      if (response) {
        isAvailable.value = true;
      }
    } catch (e) {
      isAvailable.value = false;
    }
  }

  watchDebounced(
    directionsIds,
    async () => {
      if (!directionsIds.value || directionsIds.value.length < 2) {
        isAvailable.value = false;
        return;
      }
      if (directionsIds.value[0] === directionsIds.value[1]) {
        isAvailable.value = false;
        return;
      }
      await checkAvailability();
    },
    { debounce: 300, maxWait: 1000, immediate: true },
  );

  async function swap() {
    if (!isAvailable.value) {
      return;
    }
    const [fromId, toId] = directionsIds.value as [number, number];

    await onCurrenciesChange(toId, fromId);
  }

  return {
    isAvailable,
    swap,
  };
}
