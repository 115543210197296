<template>
  <div :class="[$style.ExchangeOperatingMode, { [$style.desktop]: isDesktop }]">
    <div :class="$style.point"></div>
    <NuxtLink to="contacts">
      Время работы 24/7
      <br />
      <template v-if="isNewYear">{{ isNewYear }}</template>
      <template v-else
        >Тех. поддержка с {{ technicalSupportTime }} по мск</template
      >
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  isDesktop: {
    type: Boolean,
    default: false,
  },
  isWeekend: {
    type: Boolean,
    required: true,
  },
  isNewYear: {
    type: String,
    required: false,
  },
});

const technicalSupportTime = computed(() =>
  props.isWeekend ? "08:00 до 00:00" : "08:00 до 00:00",
);
</script>

<style lang="scss" module>
.ExchangeOperatingMode {
  display: flex;
  gap: 0.6rem;
  font-size: 1.5rem;
  align-items: flex-start;

  @include respond-to(sm) {
    font-size: 14px;
  }
}

.point {
  width: 1.2rem;
  border-radius: 50%;
  height: 1.2rem;
  background-color: $brand-green;
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  flex-shrink: 0;
  margin-top: 0.3rem;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.desktop {
  font-size: 1.8rem;
  width: 19rem;

  .point {
    margin-top: 0.5rem;
  }
}

.link {
  display: block;
  text-decoration: underline;
}
</style>
