<template>
  <button type="button" :class="$style.HomeExchangeSwap" @click="onSwapClick">
    <SvgoIconSwap :class="$style.icon" />
    <SvgoIconSwapHorizontal :class="$style.iconMob" />
  </button>
</template>

<script setup lang="ts">
const emit = defineEmits(["swap"]);
function onSwapClick() {
  emit("swap");
}
</script>

<style lang="scss" module>
.HomeExchangeSwap {
  position: absolute;
  top: 20%;
  right: calc(50% - 2.4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  transform: translate(-50%, 0%);
  z-index: 1;
  cursor: pointer;

  @include respond-to(sm) {
    top: 18%;
  }

  @include respond-to(xs) {
    position: relative;
    top: unset;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.icon {
  width: 2.4rem;
  height: 2.4rem;

  @include respond-to(xs) {
    display: none;
  }
}

.iconMob {
  display: none;
  width: 2.4rem;
  height: 2.4rem;

  @include respond-to(xs) {
    display: block;
  }
}
</style>
